import React from 'react';
import styles from '../../styles/components/page/PageHeader.module.css';
import useMediaQuery from "../../hooks/useMediaQuery";
import borders from "../../styles/Breakpoints.module.css";
import HistoryBackButton from "../../../components/HistoryBackButton";
import {ReactComponent as ArrowLeftBlueIcon} from '../../../common/assets/icons/arrow_left_blue.svg';

export interface HeaderTitles {
  title: React.ReactNode | string
  subtitle?: React.ReactNode | string
}

interface TitleContainerProps extends HeaderTitles {
  className: string
}
const TitleContainer: React.FC<TitleContainerProps> = ({ title, subtitle, className }) => (
  <div className={className}>
    <span className={styles.title}>{title}</span>
    {subtitle && (
      <>
        <span className={styles.separator}/>
        <span className={styles.subtitle}>{subtitle}</span>
      </>
    )}
  </div>
);

export interface PageHeaderProps extends HeaderTitles {
  backAction?: boolean
}
const PageHeader: React.FC<PageHeaderProps> = ({ title, subtitle, backAction }) => {
  const up1024 = useMediaQuery(borders.lg);
  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          {backAction ? (
            <>
              <div className={styles.backActionContainer}>
                <HistoryBackButton
                  render={(text) => up1024 ? 'Back to ' + text : undefined}
                  type="secondary"
                  paddingHorizontal={up1024 ? undefined : 16}
                  icon={{
                    source: <ArrowLeftBlueIcon/>
                  }}
                />
              </div>
              <TitleContainer
                title={title}
                subtitle={subtitle}
                className={`${subtitle ? styles.titleContainerWithBothTitles : styles.titleContainerWithOnlyTitle}`}
              />
            </>
          ) : (
            <TitleContainer
              title={title}
              subtitle={subtitle}
              className={styles.titleContainer}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default PageHeader;