import React, {useEffect, useState} from 'react';
import {PharmacyFavorite, PharmacyProfile, PharmacySectionName as SectionName} from "../../../model/pharmacy";
import {isNotEmpty} from "../../../common/utils/common";
import styles from "../../../styles/components/pharmacy/card/BasicInfoCard.module.css";
import {ReactComponent as LeaveBlueIcon} from '../../../common/assets/icons/leave_blue.svg';
import {ReactComponent as StarLightGreyIcon} from '../../../common/assets/icons/star_light_grey.svg';
import {ReactComponent as StarYellowIcon} from '../../../common/assets/icons/star_yellow.svg';
import {ReactComponent as AnchorLinkIcon} from '../../../common/assets/icons/anchor_link_white.svg';
import ActionButton from "../../../common/components/ActionButton";
import RedirectButton from "../../../common/components/RedirectButton";
import {Link} from "react-router-dom";
import {useErrorUpdateContext} from "../../../contexts/ErrorContext";
import Loading from "../../../common/components/Loading";
import api from "../../../rest/api";
import _ from "lodash";

export interface BasicInfoCardProps {
  pharmacyProfile: PharmacyProfile
}

const BasicInfoCard: React.FC<BasicInfoCardProps> = ({ pharmacyProfile }) => {

  const updateError = useErrorUpdateContext();

  const { basicInfo } = pharmacyProfile;
  const { name, address1, address2, city, state, zipcode, phone1, website, ownershipType } = basicInfo || {};

  const [isFavorite, setFavorite] = useState(basicInfo?.isFavorite);
  const [updatingFavorite, setUpdatingFavorite] = useState<PharmacyFavorite | null>(null);

  useEffect(() => {
    let ignore = false;
    async function updateData() {
      try {
        if(updatingFavorite) {
          await api.updatePharmacyFavorite(updatingFavorite);
          if(!ignore) {
            setFavorite(updatingFavorite.isFavorite);
            setUpdatingFavorite(null);
          }
        }
      }
      catch(error) {
        if(!ignore) {
          setUpdatingFavorite(null);
          updateError(error);
        }
      }
    }
    updateData();
    return () => {
      ignore = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatingFavorite]);

  return basicInfo && isNotEmpty(basicInfo) ? (
    <div className={styles.container}>
      {ownershipType === 'IDN' && (
        <div className={styles.headerWrapperIDN}>
          <div className={styles.header}>
            <span className={styles.headerTitleIDN}>
              IDN-Owned / Institutional Specialty Pharmacy
            </span>
          </div>
        </div>
      )}
      <div className={ownershipType === 'IDN' ? styles.contentWrapperIDN : styles.contentWrapper}>
        <div className={styles.content}>
          <div className={styles.titleContainer}>
            <span className={styles.title}>
              <span>
                {isNotEmpty(name) ? name : 'Other'}
              </span>
              <Link to={'#' + SectionName.BasicInfo} className={styles.anchor}>
                <AnchorLinkIcon/>
              </Link>
            </span>
            <span className={styles.favoriteWrapper}>
              {updatingFavorite ? <Loading theme="white" size={10} paddingVertical={0} paddingHorizontal={16} /> : (
                <span
                  className={styles.favorite}
                  onClick={() =>
                    setUpdatingFavorite({id: pharmacyProfile.id, isFavorite: !isFavorite})
                  }
                >
                  <span className={styles.favoriteIcon}>
                    {isFavorite ? <StarYellowIcon/> : <StarLightGreyIcon/>}
                  </span>
                  <span className={styles.favoriteLabel}>
                    Favorite
                  </span>
                </span>
              )}
            </span>
          </div>
          <div className={styles.address}>
            {
              _.chain([
                address1,
                address2,
                _.chain([city, state, zipcode])
                  .filter(isNotEmpty)
                  .join(' ')
                  .value()
              ])
                .filter(isNotEmpty)
                .map((addressPart, idx) => <span key={idx}>{addressPart}</span>)
                .value()
            }
          </div>
          {isNotEmpty(phone1) && (
            <span className={styles.phone}>
              {phone1}
            </span>
          )}
          <div className={styles.actionContainer}>
            {ownershipType === 'IDN' && (
              <RedirectButton
                text="Contact us about this SP"
                type="primary"
                to={'/customAnalysis/' + pharmacyProfile.id}
              />
            )}
            {isNotEmpty(website) && (
              <ActionButton
                text="Go to Website"
                type="secondaryAlt"
                icon={{
                  source: <LeaveBlueIcon/>,
                  position: "right"
                }}
                onClick={() => {
                  const url = (!website?.match(/^https?:\/\//i) ? 'http://' : '') + website;
                  window.open(url, "_blank");
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  ) : <></>
};

export default BasicInfoCard;