import client from "./client";
import {RestApi} from "./api";
import {UserAccount} from "../model/user";
import {AreaListResponse} from "../model/area";
import {CustomAnalysisRequest} from "../model/customAnalysis";
import {
  PharmacyFavoriteUpdateRequest, PharmacyFavoriteUpdateResponse,
  PharmacyProfileRequest,
  PharmacyProfileResponse,
  PharmacySnapshotListRequest,
  PharmacySnapshotListResponse
} from "../model/pharmacy";
import {ServiceListResponse} from "../model/service";
import {PayerParticipationListResponse} from "../model/payer";
import {BiopharmaParticipationListResponse} from "../model/biopharma";
import {SupportRequest, SupportTopicListResponse} from "../model/support";
import {stringify} from "../common/hooks/useParams";

export class RealApi implements RestApi {

  private defaultHeaders = {
    'Accept': 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
  };

  /**
   * Therapeutic Area
   */
  findAreas(): Promise<AreaListResponse> {
    return client.get({
      url: '/api/v1/therapeutic-area',
      headers: this.defaultHeaders,
    });
  }

  /**
   * Service
   */
  findServices(): Promise<ServiceListResponse> {
    return client.get({
      url: '/api/v1/service',
      headers: this.defaultHeaders,
    });
  }

  /**
   * Payer Network Participation
   */
  findPayerParticipation(): Promise<PayerParticipationListResponse> {
    return client.get({
      url: '/api/v1/network-participation/payer',
      headers: this.defaultHeaders,
    });
  }

  /**
   * Biopharma Network Participation
   */
  findBiopharmaParticipation(): Promise<BiopharmaParticipationListResponse> {
    return client.get({
      url: '/api/v1/network-participation/biopharma',
      headers: this.defaultHeaders,
    });
  }

  /**
   * Pharmacy Profile
   */
  findPharmacyProfile(request: PharmacyProfileRequest): Promise<PharmacyProfileResponse> {
    return client.get({
      url: '/api/v1/profile/' + request.id,
      headers: this.defaultHeaders,
    });
  }

  /**
   * Pharmacy Snapshot
   */
  findPharmacySnapshots(request: PharmacySnapshotListRequest): Promise<PharmacySnapshotListResponse> {
    return client.get({
      url: '/api/v1/snapshot',
      headers: this.defaultHeaders,
      params: request,
      paramsSerializer: stringify
    });
  }

  /**
   * Pharmacy Favorite
   */
  updatePharmacyFavorite(request: PharmacyFavoriteUpdateRequest): Promise<PharmacyFavoriteUpdateResponse> {
    if(request.isFavorite) {
      return client.put({
        url: '/api/v1/favorite/' + request.id,
        headers: this.defaultHeaders
      });
    } else {
      return client.del({
        url: '/api/v1/favorite/' + request.id,
        headers: this.defaultHeaders
      });
    }
  }

  /**
   * Custom Analysis
   */
  requestCustomAnalysis(request: CustomAnalysisRequest): Promise<string> {
    return client.post({
      url: '/api/v1/custom-analysis',
      headers: this.defaultHeaders,
      data: request
    });
  }

  /**
   * Support
   */
  requestSupport(request: SupportRequest): Promise<string> {
    return client.post({
      url: '/api/v1/support',
      headers: this.defaultHeaders,
      data: request
    });
  }
  findSupportTopics(): Promise<SupportTopicListResponse> {
    return client.get({
      url: '/api/v1/support/topics',
      headers: this.defaultHeaders
    });
  }

  /**
   * User
   */
  findUser(): Promise<UserAccount> {
    return client.get({
      url: '/api/v1/user-account',
      headers: this.defaultHeaders,
      withoutInterceptors: true
    });
  }
}