import React from 'react';
import styles from '../styles/components/ActionButton.module.css';
import {MoonLoader} from "react-spinners";
import _ from 'lodash';

export interface ActionButtonIcon {
  source?: JSX.Element | string;
  size?: 'xsmall' | 'small' | 'xnormal' | 'normal' | 'xlarge' | 'large';
  position?: 'top' | 'left' | 'right';
  offset?: 'small' | 'normal' | 'large'
}

export interface ActionButtonProps {
  text?: string;
  type?:
    | 'primary'
    | 'primaryAlt'
    | 'secondary'
    | 'secondaryAlt'
    | 'secondaryAlt2'
    | 'link'
    | 'icon';
  fontSize?: number
  paddingVertical?: number
  paddingHorizontal?: number
  icon?: ActionButtonIcon;
  disabled?: boolean;
  shadowed?: boolean;
  loading?: boolean
  onClick: () => void;
}

const ActionButton: React.FC<ActionButtonProps> = ({
  text,
  type = 'primary',
  fontSize = 14,
  paddingHorizontal = 31,
  paddingVertical = 10,
  icon = {},
  disabled = false,
  shadowed = true,
  loading = false,
  onClick,
}) => {
  const _icon = { ...{ size: 'normal', position: 'left', offset: 'large' }, ...icon };

  const Icon = () => {
    if(_icon.source) {
      if (typeof _icon.source === 'string') {
        return (
          <img
            className={`
              ${styles[type + 'Icon']} 
              ${styles[_icon.size + 'Icon']} 
              ${text ? `${styles[_icon.position + 'Icon']} ${styles[_icon.offset + 'Offset']}` : ''}
            `}
            src={_icon.source}
            alt="action button"
          />
        )
      } else {
        return (
          <span className={`
            ${styles[type + 'Icon']} 
            ${styles[_icon.size + 'Icon']} 
            ${text ? `${styles[_icon.position + 'Icon']} ${styles[_icon.offset + 'Offset']}` : ''}
          `}>
            {_icon.source}
          </span>
        )
      }
    }
    return null;
  };

  return (
    <div
      onClick={onClick}
      style={{
        fontSize: `${fontSize}px`,
        padding: `${paddingVertical}px ${paddingHorizontal}px`
      }}
      className={`${styles[type + 'Container']} ${
        (disabled || loading) ? styles.disabled : ''
      } ${_icon.position === 'top' ? styles.vertical : styles.horizontal} ${
        shadowed ? styles.shadowed : ''
      }`}
    >
      {_icon.position !== 'right' && <Icon/>}
      {loading && (
        <div className={styles.loadingIcon}>
          <MoonLoader color={_.startsWith(type, 'primary') ? '#FFFFFF' : '#002F6C'} size={20} />
        </div>
      )}
      {text && <span className={styles[type + 'Text']}>{text}</span>}
      {_icon.position === 'right' && <Icon/>}
    </div>
  );
};

export default ActionButton;

