import React, { useState } from 'react';
import { useField, FieldAttributes } from 'formik';
import styles from '../../styles/components/form/TextField.module.css';
import dropdownStyles from '../../styles/components/form/DropdownField.module.css';
import Dropdown, { DropdownOption } from '../../../common/components/Dropdown';

export interface DropdownFieldProps {
  value?: string;
  options: DropdownOption<string>[];
  label: string;
  labelNote?: string;
  wrapperClassName?: string;
  inputWrapperClassName?: string;
  inputClassName?: string;
  variant?: 'thick';
}

const DropdownField: React.FC<FieldAttributes<DropdownFieldProps>> = props => {
  const [field, meta] = useField(props);
  const [dropdownValue, setDropDownValue] = useState(props.value);
  const {
    options,
    label,
    labelNote,
    wrapperClassName,
    inputWrapperClassName,
    inputClassName,
    variant,
    ...inputProps
  } = props;
  const withError = meta.touched && meta.error;
  return (
    <div className={[styles.wrapper, wrapperClassName].join(' ')}>
      <label>
        <span className={styles.labelWrapper}>
          <span className={styles.label}>{label}</span>
          {labelNote && (
            <span className={styles.labelNote}> &mdash; {labelNote}</span>
          )}
        </span>
        <span
          className={[
            styles.inputWrapper,
            dropdownStyles.inputWrapper,
            withError ? styles.inputWithError : '',
            inputWrapperClassName,
            variant === 'thick' ? styles.thick : '',
          ].join(' ')}
        >
          <Dropdown
            value={dropdownValue}
            options={options}
            onChange={value => {
              // In order to pass a value into Formik, we need to call field's onChange prop function manually
              field.onChange({
                target: { name: props.name, value: value } as unknown,
              } as React.ChangeEvent);
              setDropDownValue(value);
            }}
            inForm={true}
            placeholder="--"
          />
          <input
            type="hidden"
            value={dropdownValue}
            {...field}
            {
              /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
              ...(inputProps as any)
            }
          />
        </span>
      </label>
      {withError && (
        <div className={styles.error}>{withError ? meta.error : ''}&nbsp;</div>
      )}
    </div>
  );
};

export default DropdownField;
