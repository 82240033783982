import {MultipleSection, PartialNullable, SinglePlainSection, SingleSection} from "../common/utils/types";
import {PaginationParams, WithId} from "./common";
import {AreaId} from "./area";
import {ServiceId} from "./service";
import {PayerParticipationId} from "./payer";
import {BiopharmaParticipationId} from "./biopharma";

/**
 * Pharmacy Profile
 */
export type PharmacyProfile = WithId<PharmacyId> & PartialNullable<{
  basicInfo: SinglePlainSection<BasicInfo>
  insights: SingleSection<DefaultContent>
  accountSummary: SingleSection<DefaultContent[]>
  swotAnalysis: SinglePlainSection<SwotAnalysis>
  organizationOverview: SinglePlainSection<OrganizationOverview>
  corporateStrategy: MultipleSection<CorporateStrategyContent>
  businessPerformance: MultipleSection<BusinessPerformanceContent>
  therapeuticAreas: MultipleSection<TherapeuticAreaContent>
  services: MultipleSection<NamedContent>
  technologyOfferings: MultipleSection<OfferingsContent>
  payerNetwork: MultipleSection<NamedContent>
  biopharmaNetwork: MultipleSection<NamedContent>
  ldn: MultipleSection<ProductContent>
  accreditation: MultipleSection<DefaultContent>
}>
export type PharmacyId = number
export type OwnershipType = 'Independent' | 'IDN'

/**
 * Pharmacy Snapshot
 */
export type PharmacySnapshot = Omit<
  PharmacyProfile,
  'insights' | 'accountSummary' | 'swotAnalysis' | 'organizationOverview' | 'corporateStrategy' | 'businessPerformance' | 'technologyOfferings'
> & PartialNullable<{
  percentMatch: number
}>

/**
 * Pharmacy Sections
 */
export type BasicInfo = PartialNullable<{
  name: string
  address1: string
  address2: string
  city: string
  state: string
  zipcode: string
  country: string
  phone1: string
  website: string
  foundedYear: number
  ownershipType: OwnershipType
  isFavorite: boolean
}>
export type SwotAnalysis = PartialNullable<{
  [key in 'strength' | 'weakness' | 'opportunity' | 'threat']: SingleSection<DefaultContent>
}>
export type OrganizationOverview = PartialNullable<{
  parentOrganizationName: string
  acquisition: string
  marketCoverageDescription: string
  organizationFacility: string
  facilityName: string
  marketCoverageCount: string
  accreditationType: MultipleSection<DefaultContent>
}>

/**
 * Pharmacy Section Names
 */
export enum PharmacySectionName {
  BasicInfo = 'basic-info',
  Insights = 'insights',
  AccountSummary = 'account-summary',
  SwotAnalysis = 'swot-analysis',
  Overview = 'overview',
  CorporateStrategy = 'corporate-strategy',
  BusinessPerformance = 'business-performance',
  TherapeuticAreas = 'therapeutic-areas',
  Services = 'services',
  TechnologyOfferings = 'technology-offerings',
  PayerNetwork = 'payer-network',
  BiopharmaNetwork = 'biopharma-network',
  LimitedDistributionNetwork = 'limited-distribution-network',
  CustomAnalysis = 'custom-analysis'
}

/**
 * Pharmacy Section Contents
 */
export type DefaultContent = string
export type NamedContent = PartialNullable<{
  id: number
  name: string
}>
export type CorporateStrategyContent = PartialNullable<{
  goal: string
  tactic: string
}>
export type TherapeuticAreaContent = PartialNullable<{
  id: number
  name: string
  treatmentCategory: string
}>
export type OfferingsContent = PartialNullable<{
  name: string
  description: string
}>
export type BusinessPerformanceContent = PartialNullable<{
  financialYear: number,
  description: string
}>
export type ProductContent = PartialNullable<{
  brandName: string
  genericName: string
  manufacturer: string
}>

/**
 * Pharmacy Profile Request/Response
 */
export type PharmacyProfileFilter = {
  id: PharmacyId
}
export type PharmacyProfileRequest = PharmacyProfileFilter
export type PharmacyProfileResponse = PharmacyProfile

/**
 * Pharmacy Snapshot List Request/Response
 */
export type PharmacyFilterParams = Partial<{
  ta: AreaId[]
  s: ServiceId[]
  pnp: PayerParticipationId[]
  bnp: BiopharmaParticipationId[]
  ot: OwnershipTypeParams
  f: OnlyFavoritesParams
}>
export type PharmacyListFilterParams = PharmacyFilterParams & PaginationParams
export type PharmacySnapshotListRequest = PharmacyListFilterParams
export type PharmacySnapshotListResponse = {
  snapshots: PharmacySnapshot[]
  total: number
}
export const defaultPharmacyListPaginationParams: PaginationParams = {
  os: 0,
  li: 5
};
export const pharmacyFilterSelectedItemsLimit = 50;
export enum OwnershipTypeParams {
  None = 0,
  Independent = 1,
  IDN = 2,
  All = 3
}
export enum OnlyFavoritesParams {
  No = 0,
  Yes = 1
}

/**
 * Pharmacy Favorite Request/Response
 */
export type PharmacyFavorite = {
  id: PharmacyId,
  isFavorite: boolean
}
export type PharmacyFavoriteUpdateRequest = PharmacyFavorite
export type PharmacyFavoriteUpdateResponse = {}