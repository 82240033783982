import React, {useEffect, useRef, useState} from 'react';
import styles from '../../styles/components/pages/ProfilePage.module.css';
import DefaultPage from "./DefaultPage";
import {RouteComponentProps, useLocation, withRouter} from "react-router";
import {ProfileRouterParams} from "../../router/params/ProfileRouterParams";
import {FnEmpty, isNotEmpty} from "../../common/utils/common";
import Loading from "../../common/components/Loading";
import {useErrorUpdateContext} from "../../contexts/ErrorContext";
import {Element as ScrollElement, scroller} from 'react-scroll/modules';
import {ReactComponent as ArrowLeftGreyIcon} from '../../common/assets/icons/arrow_left_grey.svg';
import {ReactComponent as ArrowRightGreyIcon} from '../../common/assets/icons/arrow_right_grey.svg';
import {ReactComponent as MenuGreyIcon} from '../../common/assets/icons/menu_grey.svg';
import {ReactComponent as AnchorLinkIcon} from '../../common/assets/icons/anchor_link_black.svg';
import {PharmacyProfile, PharmacySectionName as SectionName} from "../../model/pharmacy";
import AccountSummaryList from "../pharmacy/list/AccountSummaryList";
import InsightsText from "../pharmacy/text/InsightsText";
import SWOTAnalysisTable from "../pharmacy/table/SWOTAnalysisTable";
import CorporateStrategyTable from "../pharmacy/table/CorporateStrategyTable";
import BusinessPerformanceTable from "../pharmacy/table/BusinessPerformanceTable";
import TherapeuticAreaTable from "../pharmacy/table/TherapeuticAreaTable";
import ServiceList from "../pharmacy/list/ServiceList";
import TechnologyOfferingTable from "../pharmacy/table/TechnologyOfferingTable";
import PayerNetworkList from "../pharmacy/list/PayerNetworkList";
import BiopharmaNetworkList from "../pharmacy/list/BiopharmaNetworkList";
import LimitedDistributionNetworkTable from "../pharmacy/table/LimitedDistributionNetworkTable";
import BasicInfoCard from "../pharmacy/card/BasicInfoCard";
import CustomAnalysisCard from "../pharmacy/card/CustomAnalysisCard";
import {Link} from "react-router-dom";
import OverviewTables from "../pharmacy/table/OverviewTables";
import NotifyPopup from "../pharmacy/popup/NotifyPopup";
import VisibilitySensor from 'react-visibility-sensor';
import {Cancelable, action} from "../../common/utils/types";
import {ifNegative, parseNumber} from "../../common/utils/number";
import {
  SectionPopupActionType,
  useSectionPopupContext,
  useSectionPopupDispatchContext
} from "../../contexts/SectionPopupContext";
import {getById} from "../../utils/dom";
import {random} from "../../common/utils/array";
import api from "../../rest/api";
import _ from 'lodash';

interface SectionProps {
  name: string
  title?: string
  popupMessage?: string
  className?: string
  content?: React.ReactNode
}

const Section: React.FC<SectionProps> = ({ name, title, popupMessage, className, content }) => {

  const headerRef = useRef<HTMLDivElement>(null);
  const [sectionPopups, sectionPopupsDispatch] = [useSectionPopupContext(), useSectionPopupDispatchContext()];

  const [isOnScreen, setIsOnScreen] = useState(false);
  const [popupShowed, setPopupShowed] = useState(false);

  const delayedOnScreen = useRef<FnEmpty & Cancelable>(_.debounce(() => {
    if(headerRef.current) {
      setIsOnScreen(true);
    }
  }, 20000));

  useEffect(() => {
    if(isOnScreen) {
      console.log(`[popup]: try to show "${name}" ...`);
      if(!sectionPopups.popupShowed && !_.get(sectionPopups.popups, name) && headerRef.current) {
        console.log(`[popup]: show "${name}"`);
        sectionPopupsDispatch(action(SectionPopupActionType.Popup, name as SectionName));
        setPopupShowed(true);
      }
      setIsOnScreen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnScreen]);

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      delayedOnScreen.current.cancel();
    }
  }, []);

  return (
    <VisibilitySensor
      partialVisibility={true}
      offset={{top: 5}}
      onChange={!popupMessage ? undefined : (isVisible: boolean) => {
        isVisible ? delayedOnScreen.current() : delayedOnScreen.current.cancel();
      }}
    >
      <ScrollElement name={name} className={`${styles.section} ${className || ''}`}>
        {title && (
          <div ref={headerRef} className={styles.sectionHeader}>
            <span className={styles.sectionTitle}>
              {title}
            </span>
            <Link to={'#' + name} className={styles.sectionTitleAnchor}>
              <AnchorLinkIcon/>
            </Link>
            {popupShowed && popupMessage && headerRef.current && (
              <NotifyPopup
                message={popupMessage}
                target={headerRef.current}
                onHide={() => setPopupShowed(false)}
              />
            )}
          </div>
        )}
        <div className={styles.sectionContent}>
          {content}
        </div>
      </ScrollElement>
    </VisibilitySensor>
  )
};

type ProfilePageProps = RouteComponentProps<ProfileRouterParams>;

const ProfilePage: React.FC<ProfilePageProps> = ({ history, match }) => {

  const updateError = useErrorUpdateContext();

  const sidebarRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<() => void>(() => {});

  const { hash } = useLocation();

  const [profile, setPharmacyProfile] = useState<PharmacyProfile | null>(null);

  const [sidebarExpanded, setSidebarExpanded] = useState<boolean>(false);
  const [sidebarContentShowed, setSidebarContentShowed] = useState<boolean>(false);

  const [loading, setLoading] = useState(true);

  const pharmacyId = parseNumber(match.params.pharmacyId);

  const {
    basicInfo,
    insights,
    accountSummary,
    swotAnalysis,
    organizationOverview,
    corporateStrategy,
    businessPerformance,
    therapeuticAreas,
    services,
    technologyOfferings,
    payerNetwork,
    biopharmaNetwork,
    ldn
  } = profile || {};

  const expandSidebar = () => {
    setSidebarExpanded(true);
    setTimeout(() => {
      setSidebarContentShowed(true);
    }, 500);
  };

  const collapseSidebar = () => {
    setSidebarExpanded(false);
    setSidebarContentShowed(false);
  };

  const handleAnchor = (name: string) => {
    scroller.scrollTo(name, {
      duration: 400,
      delay: 0,
      offset: 0,
      smooth: 'easeInOutQuart'
    });
    collapseSidebar();
  };

  const sections = profile ? _.filter([
    {
      name: SectionName.BasicInfo,
      toc: 'Basic Info',
      className: styles.basicInfo,
      content: isNotEmpty(basicInfo) && (
        <BasicInfoCard pharmacyProfile={profile} />
      )
    }, {
      name: SectionName.Insights,
      title: 'EVERSANA’s Insights',
      popupMessage:
        basicInfo?.ownershipType !== 'IDN' ?
          'Would you like help understanding what makes this SP really worth your consideration for network participation? Contact us.' :
          undefined,
      className: styles.insights,
      content: isNotEmpty(insights) && (
        <InsightsText pharmacyProfile={profile} />
      )
    }, {
      name: SectionName.AccountSummary,
      title: 'Account Summary & Analysis',
      popupMessage:
        basicInfo?.ownershipType === 'IDN' ?
          'Would you like help understanding how to best integrate institution-owned SPs into a broad network with independent SPs? Contact us.' :
          'Would you like help understanding how SP consolidations/integrations/alliances may be disrupting your future business goals? Contact us.',
      content: isNotEmpty(accountSummary) && (
        <AccountSummaryList pharmacyProfile={profile} className={styles.bulletedListTable} />
      )
    }, {
      name: SectionName.SwotAnalysis,
      title: 'SWOT Analysis',
      content: isNotEmpty(swotAnalysis) && (
        <SWOTAnalysisTable pharmacyProfile={profile} className={styles.matrixTable} />
      )
    }, {
      name: SectionName.Overview,
      title: 'Organization Overview and Structure',
      content: isNotEmpty(organizationOverview) && (
        <OverviewTables pharmacyProfile={profile} className={styles.simpleTable} itemClassName={{label: styles.tableLabel, content: styles.tableContent}} />
      )
    }, {
      name: SectionName.CorporateStrategy,
      title: 'Corporate Strategy',
      content: isNotEmpty(corporateStrategy) && (
        <CorporateStrategyTable pharmacyProfile={profile} className={styles.commonTable} />
      )
    }, {
      name: SectionName.BusinessPerformance,
      title: 'Business Performance',
      popupMessage:
        basicInfo?.ownershipType !== 'IDN' ?
          'Would you like help understanding the business analytics of this SP? Contact us.' :
          undefined,
      content: isNotEmpty(businessPerformance) && (
        <BusinessPerformanceTable pharmacyProfile={profile} className={styles.commonTable} />
      )
    },
    {
      name: SectionName.TherapeuticAreas,
      title: 'Areas of Therapeutic Focus',
      content: isNotEmpty(therapeuticAreas) && (
        <TherapeuticAreaTable pharmacyProfile={profile} className={styles.commonTable} />
      )
    }, {
      name: SectionName.Services,
      title: 'Services Offered',
      popupMessage:
        basicInfo?.ownershipType !== 'IDN' ?
          random([
            'Beyond your core services selected here, would you like help understanding what really differentiate these SPs in both capabilities and capacities? Contact us.',
            'Would you like help understanding the critical attributes most important for seamless product flow and business operations? Contact us.'
          ]) :
          undefined,
      content: isNotEmpty(services) && (
        <ServiceList pharmacyProfile={profile} className={styles.bulletedListTable} />
      )
    }, {
      name: SectionName.TechnologyOfferings,
      title: 'Technology Offerings',
      content: isNotEmpty(technologyOfferings) && (
        <TechnologyOfferingTable pharmacyProfile={profile} className={styles.commonTable} />
      )
    }, {
      name: SectionName.PayerNetwork,
      title: 'Payer Network Participation',
      content: isNotEmpty(payerNetwork) && (
        <PayerNetworkList pharmacyProfile={profile} className={styles.bulletedListTable} />
      )
    }, {
      name: SectionName.BiopharmaNetwork,
      title: 'Biopharma Network Participation',
      content: isNotEmpty(biopharmaNetwork) && (
        <BiopharmaNetworkList pharmacyProfile={profile} className={styles.bulletedListTable} />
      )
    }, {
      name: SectionName.LimitedDistributionNetwork,
      title: 'Limited Distribution Network',
      popupMessage:
        basicInfo?.ownershipType !== 'IDN' ?
          'Would you like help understanding which of these SPs work better in a portfolio-based network vs. a brand-specific network? Contact us.' :
          undefined,
      content: isNotEmpty(ldn) && (
        <LimitedDistributionNetworkTable pharmacyProfile={profile} className={styles.commonTable} />
      )
    }, {
      name: SectionName.CustomAnalysis,
      toc: 'Get in Touch — Custom Analysis',
      className: styles.customAnalysis,
      content: basicInfo?.ownershipType === 'IDN' && (
        <CustomAnalysisCard pharmacyProfile={profile} />
      )
    }
  ], rowOfContents => !!rowOfContents.content) : [];

  useEffect(() => {
    let ignore = false;
    async function fetchData(){
      try {
        if(pharmacyId) {
          const data = await api.findPharmacyProfile({ id: pharmacyId });
          if(!ignore) {
            setLoading(false);
            setPharmacyProfile(data);
          }
        }
      }
      catch(error) {
        setLoading(false);
        updateError(error);
      }
    }
    fetchData();
    return () => {
      ignore = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(profile && isNotEmpty(hash)) {
      const sectionName = hash.replace('#', '');
      handleAnchor(sectionName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile]);


  useEffect(() => {
    scrollRef.current = () => {
      const sidebar = sidebarRef.current;
      if(sidebar) {

        const scrollTopY = document.documentElement.scrollTop || document.body.scrollTop;
        const windowHeight = window.innerHeight;

        const headerHeight = getById('header')?.offsetHeight || 0;
        const footerTopY = getById('footer')?.offsetTop || 0;

        const offsetTopY = ifNegative(headerHeight - scrollTopY, 0);
        const offsetBottomY = ifNegative(scrollTopY + windowHeight - footerTopY, 0);

        sidebar.style.height = windowHeight - offsetTopY - offsetBottomY + 'px';
      }
    };
    scrollRef.current();
    document.addEventListener('scroll', scrollRef.current);
    return () => {
      document.removeEventListener('scroll', scrollRef.current);
    };
  }, []);

  return (
    <DefaultPage
      name='Profile'
      header={{
        title: 'Specialty Pharmacy Profile',
        backAction: true
      }}
      scrollToTop={true}
      wideContent={true}
    >
      <div className={styles.container}>
        {loading ? <Loading paddingVertical={64} fullHeight={false} fullWidth={true} /> : profile && (
          <>
            <div className={styles.sidebarWrapper}>
              <div ref={sidebarRef} className={`${styles.sidebar} ${sidebarExpanded ? styles.expandedWidth : styles.collapsedWidth}`}>
                <div className={`${styles.sidebarCollapsedContainer} ${sidebarExpanded ? styles.hide : ''}`}>
                  <div className={styles.sidebarHeader}>
                    <span className={styles.sidebarMenu} onClick={expandSidebar}>
                      <MenuGreyIcon/>
                      <ArrowRightGreyIcon/>
                    </span>
                  </div>
                </div>
                <div className={`${styles.sidebarExpandedContainer} ${!sidebarExpanded ? styles.hide : ''}`}>
                  <div className={`${styles.sidebarContent} ${!sidebarContentShowed ? styles.hide : ''}`}>
                    <span>Table of Contents</span>
                    {_.map(sections, ({name, toc, title}) =>
                      <span key={name} onClick={() => handleAnchor(name)}>{toc || title}</span>
                    )}
                  </div>
                  <div className={styles.sidebarHeader}>
                    <span className={styles.sidebarMenu} onClick={collapseSidebar}>
                      <ArrowLeftGreyIcon/>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.profile}>
              <div className={styles.content}>
                {_.map(sections, (props, idx) =>
                  <Section key={idx} {...props} />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </DefaultPage>
  )
};

export default withRouter(ProfilePage);
